<template>
  <div class="max-w-screen-md mx-auto mt-8 p-8">
    <div class="relative">
      <UInput
        v-model="searchQuery"
        color="blue"
        placeholder="請輸入關鍵字"
        size="xl"
        trailing
        type="text"
        icon="i-heroicons-magnifying-glass-20-solid"
        :ui="{ color: { white: { outline: 'dark:text-neutral-800' } } }"
        @input="handleSearch"
        @focus="handleFocus"
        @blur="handleBlur"
      />
      <UButton
        class="absolute inset-y-0 right-0 px-4 py-2 text-white font-semibold rounded-r"
        @click="searchResults.length > 0 && navigateToAccount(searchResults[0])"
      >
        搜尋
      </UButton>
      <UButton
        v-if="searchQuery !== ''"
        color="gray"
        variant="link"
        icon="i-heroicons-x-mark-20-solid"
        :padded="false"
        class="absolute inset-y-0 right-12 px-4 py-2 text-black font-semibold rounded-r"
        @click="clearAll"
      />
    </div>
    <ul class="mt-2 overflow-y-auto max-h-60 rounded bg-white w-full">
      <li
        v-for="result in searchResults"
        :key="result.name"
        class="p-2 cursor-pointer hover:bg-neutral-50 text-neutral-800"
        @click="navigateToAccount(result)"
      >
        <span v-html="highlightText(result.name)"></span>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { useAccountStore, type Account } from '@/stores/useAccountDescStore'

const accountStore = useAccountStore()
await callOnce(accountStore.fetchAccountList)

// Reactive variables
const searchQuery = ref('')
const searchResults = ref([] as Account[])
const items = ref(accountStore.accountList.accountList || [])

// Methods
const router = useRouter()
const navigateToAccount = async (account: Account) => {
  await accountStore.fetchAccountUrl(
    account.name,
    account.fundhouse,
    account.currency,
    account.company,
    account.risk,
    account.updateDatetime
  )
  router.push('account-center/' + accountStore.accountUrl.url)
}

const handleSearch = () => {
  searchResults.value = items.value.filter((item: Account) =>
    item.name.toLowerCase().includes(searchQuery.value.toLowerCase())
  )
}

watch(searchQuery, handleSearch)

const clearAll = () => {
  searchQuery.value = ''
  searchResults.value = []
}

const highlightText = (name: string) => {
  const lowerCaseLabel = name.toLowerCase()
  const lowerCaseQuery = searchQuery.value.toLowerCase()
  const index = lowerCaseLabel.indexOf(lowerCaseQuery)

  if (index !== -1) {
    const start = name.substring(0, index)
    const match = name.substring(index, index + searchQuery.value.length)
    const end = name.substring(index + searchQuery.value.length)

    return `${start}<span class="border-b-2 border-blue-500">${match}</span>${end}`
  } else {
    return name
  }
}

const handleFocus = () => {
  searchResults.value = items.value
}

const handleBlur = () => {
  setTimeout(() => {
    searchResults.value = []
  }, 200) // Delay of 200 milliseconds (0.2 second)
}
</script>
