<template>
  <div class="relative w-full h-[400px] sm:h-[500px]">
    <!-- Carousel container -->
    <div class="relative w-full h-full overflow-hidden">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="absolute w-full h-full transition-opacity duration-500 ease-in-out"
        :class="currentSlide === index ? 'opacity-100' : 'opacity-0 pointer-events-none'"
      >
        <NuxtLink :to="item.link" class="block w-full h-full">
          <!-- Image container with fixed height -->
          <img
            :src="item.image"
            :alt="item.title"
            class="w-full h-full object-cover"
            draggable="false"
          />
          <!-- Gradient overlay -->
          <div class="absolute inset-0 bg-gradient-to-b from-transparent via-black/50 to-black/80" />
          <!-- Text content -->
          <div class="absolute bottom-0 left-0 right-0 p-4 sm:p-6 lg:p-8 text-white">
            <h2 class="text-lg sm:text-xl lg:text-3xl font-bold mb-1 sm:mb-2 text-neutral-50">
              {{ item.title }}
            </h2>
            <p class="text-sm sm:text-base text-neutral-50 line-clamp-2 sm:line-clamp-none max-w-2xl">
              {{ item.description }}
            </p>
          </div>
        </NuxtLink>
      </div>
    </div>

    <!-- Navigation Arrows -->
    <button
      @click="prevSlide"
      class="absolute left-2 sm:left-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-black/30 text-white hover:bg-black/50 transition-colors"
      aria-label="Previous slide"
    >
      <Icon name="heroicons:chevron-left" class="w-4 h-4 sm:w-6 sm:h-6" />
    </button>
    <button
      @click="nextSlide"
      class="absolute right-2 sm:right-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-black/30 text-white hover:bg-black/50 transition-colors"
      aria-label="Next slide"
    >
      <Icon name="heroicons:chevron-right" class="w-4 h-4 sm:w-6 sm:h-6" />
    </button>

    <!-- Indicators -->
    <div class="absolute bottom-4 left-1/2 -translate-x-1/2 flex gap-2">
      <button
        v-for="(_, index) in items"
        :key="index"
        @click="goToSlide(index)"
        class="h-1.5 sm:h-2 rounded-full transition-all"
        :class="[
          currentSlide === index
            ? 'bg-white w-4'
            : 'bg-white/50 hover:bg-white/75 w-2'
        ]"
        :aria-label="`Go to slide ${index + 1}`"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
interface CarouselItem {
  image: string
  link: string
  title: string
  description: string
}

// Carousel data
const items = ref<CarouselItem[]>([
  {
    image: '/images/img3.jpg',
    link: '/learn/compare-investment-and-life-insurance',
    title: '投資型保單與傳統壽險的比較',
    description: '深入解析投資型保單與傳統壽險的差異，幫助您做出最佳選擇。'
  },
  {
    image: '/images/img1.jpg',
    link: '/learn/ilp-comprehensive-guide',
    title: '投資型保單完整指南',
    description: '從零開始認識投資型保單，了解其特點、優勢與風險。'
  },
  {
    image: '/images/img4.jpg',
    link: '/learn/investment-insurance-deep-dive',
    title: '投資型保險深度剖析',
    description: '探討投資型保險的核心優勢與投資策略，助您掌握理財新思維。'
  },
  {
    image: '/images/img2.jpg',
    link: '/learn/what-is-gmdb',
    title: '認識保證最低身故保險金(GMDB)',
    description: '了解GMDB如何為您的投資提供額外保障。'
  }
])

// Simple state management
const currentSlide = ref(0)

// Navigation functions
const nextSlide = () => {
  currentSlide.value = (currentSlide.value + 1) % items.value.length
}

const prevSlide = () => {
  currentSlide.value = (currentSlide.value - 1 + items.value.length) % items.value.length
}

const goToSlide = (index: number) => {
  currentSlide.value = index
}

// Start auto-advance only on client side
if (process.client) {
  setInterval(nextSlide, 10000)
}
</script>